import IRoom from "./IRoom";
import Sonne from "../Sonne";
import SnowController from "./SnowController";
import AutumnParticlesController from "./AutumnParticlesController";
import {PointLight, MeshStandardMaterial,FrontSide,MeshBasicMaterial} from 'three';

import Sky from "../../Scene/Sky";
import {GreenRoof} from "@/Enum/Enums";
import Utility from "@/utils";

class RoomApartmentBuilding implements IRoom {
  Name : String;
  Xr : any;
  ModelStack : Array < any >;
  Sun : any;
  Store : any;
  SnowController : any;
  AutumnParticlesController : any;
  Skybox : any;
  Sky : any;

  backupHouseMaterial : any = null;
  roofElement : any = null;

  grassPatch : any = [];

  isLoading : boolean = true;
  constructor(xr : any, store : any) {
    this.Name = 'ApartmentBuilding';
    this.Store = store;
    this.Xr = xr;
    this.ModelStack = [];
    this.Init();
    this.Skybox = null;

  }


  SwitchRoof(roofSetting) {

    if (this.roofElement != null && this.backupHouseMaterial != null) {
      switch (roofSetting) {
        case GreenRoof.GreenRoof:

          this.roofElement.material = this.Store.state.world.mainScene.materialController.grassController.grassMaterial;

          this.grassPatch.map(el => {
            if (el.material.name != "grassMatClone") {
              el.material = this.Store.state.world.mainScene.materialController.grassController.grassMaterial.clone();
              el.material.transparent = true;
              el.material.name = "grassMatClone"
            }
          });


          break;
        case GreenRoof.NoGreenRoof:
          this.roofElement.material = this.backupHouseMaterial;

          this.grassPatch.map(el => {
            if (el.material.name != "backupHouseClone") {
              el.material = this.backupHouseMaterial.clone();
              el.material.transparent = true;
              el.material.name = "backupHouseClone";
            }
          });

          break;
      }
    }
  }
  Init(): void {
    this.SnowController = new SnowController({xr: this.Xr, store: this.Store});
    this.Xr.SceneController.AddToScene(this.Name, this.SnowController);

    this.AutumnParticlesController = new AutumnParticlesController({xr: this.Xr, store: this.Store});
    this.Xr.SceneController.AddToScene(this.Name, this.AutumnParticlesController);

    this.Sun = new Sonne(this.Store, this.Xr);

    this.Xr.SceneController.AddToScene(this.Name, this.Sun.sun);

  }

  CreatePointLight(position) {
    let light = new PointLight();
    light.name = "PointLight";
    light.position.copy(position);
    this.Xr.SceneController.AddToScene(this.Name, light);
  }

  SetModelStack(stack, maps, lightMaps) {
    this.Store.state.world.mainScene.dashboardController.AddObjects(stack.ApartmentBuildingKabel, 'ApartmentBuilding');

    var mapForRoom = maps.filter(map => {
      return map.room == this.Name
    });
    var lightMapForRoom = lightMaps.filter(map => {
      return map.room == this.Name
    });

    Object.values(stack).map((model : any) => {
      if (model.scene.userData.Room == this.Name) { // AoMap
        
          model.scene.traverse((child) => {
            if (child.material != undefined && child.name != "participationQuote001") {
              if (mapForRoom != null && mapForRoom != undefined && mapForRoom.length > 0 && child.name != "waermepumpe_aussen") {
                child.material.aoMap = mapForRoom[0].texture;
                child.material.aoMapIntensity = 1;
              }
              if (lightMapForRoom != null && lightMapForRoom != undefined && lightMapForRoom.length > 0) {
                
                child.material.lightMap = lightMapForRoom[0].texture;
                child.material.lightMapIntensity = .7;
              }
            }
          
            if (child.name == "RoofGrass") {
              this.roofElement = child;
            }

            if (child.material ?. name == "House" && this.backupHouseMaterial == null) {
              this.backupHouseMaterial = child.material;
            }

            if (child.userData.hasOwnProperty("LightID")) {
              //this.CreatePointLight(child.position);
            }

            if (child.type == "SkinnedMesh") {
              child.frustumCulled = false;
              child.castShadow = true;
              child.receiveShadow = true;
            }

            if (child.type == "Mesh") {
              child.castShadow = true;
              child.receiveShadow = true;
            }

            if (child.name == "grass_patch") {
              this.grassPatch.push(child);
            }

            if (child.material?.name == "Alu") {

              child.material.metalness = 0.5;
              child.material.roughness = .7;
              child.material.side = FrontSide;
            }

            if (child.material?.name == "Glass") {
              child.material.transmission = 0.5;
              child.material.thickness = 0.1;
              child.material.roughness = 0.5;
            }

            if (child.material?.name == "Plane_baked" || child.material?.name == "Plane_baked.001") {
              child.material.envMapIntensity = 0.75;
              child.material.normalScale.set(.01, .01); // = 0.75;
              child.material.roughnessMap = null;
              child.material.roughness = .5;
              child.material.map.matrixAutoUpdate = false;
              child.material.side = FrontSide;

                // child.material.roughnessMap = null;
                // child.material.metalnessMap = null;
                // child.material.environmentMap = null;
              
            }

            if(child.material?.name == "Gummimatte.003"){
              child.material.side = FrontSide;
            }
          });
        this.ModelStack.push(model);
        this.Xr.SceneController.AddToScene(this.Name, model.scene)
        
      }
    })
  }

  HandleGrasPatch = (progress) => {

    this.grassPatch.map(el => {
      let opacity = progress > .4 ? 1 : 0;
      if (progress > .37 && progress < .4) {
        opacity = Utility.Remap(progress, .37, .4, 0, 1);
      }
      el.material.opacity = opacity;
    });
  }
  ResetGrasPatch = () => {

    this.grassPatch.map(el => {
      el.material.opacity = 1;
    });
  }

  Detach(arg : any): void {
    throw new Error("Method not implemented.");
  }
  Attach(arg : any): void {
    throw new Error("Method not implemented.");
  }
}

export default RoomApartmentBuilding;
