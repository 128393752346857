import IRoom from "./IRoom";
import {
  AmbientLight,
  DirectionalLight,
  MeshStandardMaterial,
  Vector3,
  ShadowMaterial,
  CameraHelper,
  Color,
  HemisphereLight,
  SphereGeometry,
  MeshBasicMaterial,
  sRGBEncoding,
  LinearEncoding,
  CanvasTexture,
  Texture,
  EquirectangularReflectionMapping,
  BackSide,
  Mesh,
  PMREMGenerator,
  Matrix4,
  FrontSide
} from 'three';
import { Easing, Tween } from "@tweenjs/tween.js";
import { Area, Room } from "@/Enum/Enums";


class RoomTinyCity implements IRoom {
  Name : String;
  Xr : any;
  store :any;
  router :any;
  ModelStack : Array < any >;
  isLoading : boolean = true;
  time:number = 0;
  selections:any = {
      Apartment: null,
      FamilyHouse: null,
      ApartmentBuilding: null
    }
  track: any = null;
  movingObjects:any = {
      bus: null,
      car1: null,
      car2: null,
      bicicle: null
  }
  canAnimate: boolean = false;
  movingObjectsAnimation:any = {}
  tweens:any = {}
  
  constructor(xr : any, store:any, router: any) {
    this.Name = 'TinyCity';
    this.Xr = xr;
    this.store = store;
    this.router = router;
    this.ModelStack = [];

    this.store.watch(state => state.viewMode, (currentViewMode)=>{
      if(currentViewMode == "tinyCity"){
        this.StartAnimation();
      }else{
        this.StopAnimation();
      }
    })
  }

  Init(): void {

    const light = new AmbientLight(0xffffff, 1.5);
    this.Xr.SceneController.AddToScene(this.Name,light);

    var directionalLight = new DirectionalLight(0xffffff, 1.58);
    directionalLight.castShadow = true;
    // Set up shadow properties for the light
    directionalLight.shadow.mapSize.width = 1024; // default
    directionalLight.shadow.mapSize.height = 1024; // default
    directionalLight.shadow.camera.near = .01; // default
    directionalLight.shadow.camera.far = 70; // default

    const d = 70;
    directionalLight.shadow.camera.left = - d; // default
    directionalLight.shadow.camera.top = d; // default
    directionalLight.shadow.camera.right = d; // default
    directionalLight.shadow.camera.bottom = - d; // default

    directionalLight.shadow.bias = -0.01;
    directionalLight.position.set(-20, 50, 20);
    this.Xr.SceneController.AddToScene(this.Name, directionalLight);
    
    
    const hemiLight = new HemisphereLight( 0xffffbb, 0x080820, 1 );
    this.Xr.SceneController.AddToScene(this.Name, hemiLight);
    
    
    this.Xr.Events.addEventListener("OnAnimationLoop", this.Animate);

    // console.log("Room %c Kitchen ist geladen" , "background:#ff9800; color:#fff;");
  }

  SetModelStack(stack : any, maps : any, lightMaps:any) {

    var mapForRoom = maps.filter(map => { return map.room == this.Name })[0];
    
    Object.values(stack).map((model : any) => {

      if (model.scene.userData.Room == this.Name) {
        const noShadowMeshes = ["ground", "selection_reihenwohnhaus", "selection_mieterstrom", "selection_balkonmodule"];

         //aoMap
         if (mapForRoom  != null && mapForRoom != undefined) {
          model.scene.traverse((child) => {
            if (child.material != undefined) {
              child.material.aoMap = mapForRoom.texture;
            }
          })
        }


        model.scene.traverse((child) => {
          if (child.type == "Mesh" && !noShadowMeshes.includes(child.name)) {
            child.castShadow = true;
            child.receiveShadow = true;
          }


          if(child.material && child.material.map != null){
            child.material.map.encoding = LinearEncoding;
          }
          
          switch (child.name) {
            case "ground":
              child.material = new ShadowMaterial();
              child.material.opacity = 0.6;
              break;
            case "selection_reihenwohnhaus":
             
              this.selections[Area.FamilyHouse] = child;
              child.material = new MeshBasicMaterial({transparent:true,opacity:0,depthWrite : false, depthTest : false});
              child.castShadow = false;
              child.userData.selected = false;
              child.userData.hovered = false;

              child.children[0].material = child.children[0].material.clone();
              //child.children[0].visible = false;
              child.children[0].material.opacity = 0.1;
              child.children[0].castShadow = false;

              
              child.isClickEnabled = true;
              child.setState = (state)=>this.SetState(state,child, Area.FamilyHouse);
              this.Xr.Controls.ActiveObjects.push(child);

            break;
            case "selection_mieterstrom":
              this.selections[Area.ApartmentBuilding] = child;
              child.material = new MeshBasicMaterial({transparent:true,opacity:0,depthWrite : false, depthTest : false});
              child.castShadow = false;
              child.userData.selected = false;
              child.userData.hovered = false;

              child.children[0].material = child.children[0].material.clone();
              //child.children[0].visible = false;
              child.children[0].material.opacity = 0.1;
              child.children[0].castShadow = false;


              child.isClickEnabled = true;
              child.setState = (state)=>this.SetState(state,child, Area.ApartmentBuilding);
              this.Xr.Controls.ActiveObjects.push(child);

              break;
            case "selection_balkonmodule":
              this.selections[Area.Apartment] = child;
              child.material = new MeshBasicMaterial({transparent:true,opacity:0,depthWrite : false, depthTest : false});
              child.castShadow = false;
              child.userData.selected = false;
              child.userData.hovered = false;

              child.children[0].material = child.children[0].material.clone();
              //child.children[0].visible = false;
              child.children[0].material.opacity = 0.1;
              child.children[0].castShadow = false;


              child.isClickEnabled = true;
              child.setState = (state)=>this.SetState(state,child, Area.Apartment);
              this.Xr.Controls.ActiveObjects.push(child);

              break;
            case "track_01":
              this.track = child;  
            break;
            case "Bus":
              this.movingObjects.bus = child;
              child.material = new MeshStandardMaterial({color: 0xF38D2F})
              break;
            case "Car1":
              this.movingObjects.car1 = child;
              child.material = new MeshStandardMaterial({color: 0xF38D2F})
              break;
            
            
            case "Car2":
              this.movingObjects.car2 = child;
              child.material = new MeshStandardMaterial({color: 0xF38D2F})
              break;
            case "Bicicle":
              this.movingObjects.bicicle = child;
              child.material = new MeshStandardMaterial({color: 0xF38D2F})
            break;
            
            // case "Plane074":
            //   this.movingObjects.boat = child;
            //   child.material = new MeshStandardMaterial({color: 0x4caf50})
            //   break;

            case "river":
              child.material = new MeshStandardMaterial({color: 0x79a6c9, roughness: 0, metalness: 0})
              break;
            case "street":
              child.material = new MeshStandardMaterial({color: 0x9e9e9e, roughness: 0.9, metalness: 0})
              break;

            // default:
            //   if(child.material != undefined){
            //     // child.material.metalness = 0;
            //     // child.material.roughness = 0.9;
            //     //child.material.normalMap = null;
            //     child.material.toneMapped = false;
            //   }
            //   break;
          }

          

        });

        this.ModelStack.push(model);
        this.Xr.SceneController.AddToScene(this.Name, model.scene);

        this.Xr.Renderer.instance.shadowMap.needsUpdate = true;

        
        this.Init();

      }
    });


    this.Xr.Renderer.instance.shadowMap.needsUpdate = true;
    this.Xr.SceneController.scenes.TinyCity.background = new Color(0xffffff);
    
   
    this.StartMovingObjects();
    // console.log("scene Background", this.Xr.SceneController.scenes.TinyCity.background)
  }

  SetState = (state, element, Arealink) =>{
    
    if(state == "hovered"){
      this.SetSelection(Arealink, true);
      element.userData.selected = false;
      element.userData.hovered = true;

    }else if(state == "selected" && !element.userData.selected ){
      element.userData.selected = true;
      element.userData.hovered = false;
      this.SetSelection(Arealink, true);
      
      switch(Arealink){
        case Area.FamilyHouse:
          this.router.push("/" + this.store.state.xrMode + "/familyhouse/configurator").catch(()=>{});
        break;
        case Area.Apartment:
          this.router.push("/" + this.store.state.xrMode + "/apartment/explore").catch(()=>{});
        break;
        case Area.ApartmentBuilding:
          this.router.push("/" + this.store.state.xrMode + "/apartmentbuilding/configurator").catch(()=>{});
        break;
      }
      
    }else if(state == "idle"){
      element.userData.selected = false;
      element.userData.hovered = false;
      this.SetSelection(Arealink, false);
    }
  }

  SetSelection = (name, visible) => {
    if(!this.selections[name]){return;}


    const selections = Object.keys(this.selections).filter((selectionName)=>{
      return this.selections[selectionName].userData.hovered;
    });

    // if(selections.length > 0){
    //   document.body.style.cursor = "pointer";
    // }else{
    //   document.body.style.cursor = "auto";
    // }

   //this.selections[name].children[0].visible = visible;
    this.selections[name].children[0].material.opacity = visible ? 1 : 0.5;

  }
  Detach(arg : any): void {
    throw new Error("Method not implemented.");
  }
  Attach(arg : any): void {
    throw new Error("Method not implemented.");
  }

  StartMovingObjects(){
    
    Object.keys(this.movingObjects).map((movingObject)=>{

      let randomIndex = Math.floor(Math.random() * this.track.children.length);
      let randomPos = this.track.children[randomIndex].position;
      let nextIndex = randomIndex + 1 < this.track.children.length ? randomIndex + 1 : 0;
      this.movingObjects[movingObject].position.copy(randomPos);

      this.movingObjectsAnimation[movingObject] = {
        object : this.movingObjects[movingObject],
        currentIndex : randomIndex,
        nextIndex : nextIndex
      }

      this.MoveSingleObject(this.movingObjectsAnimation[movingObject]);
    });
  }

  MoveSingleObject(obj){

    var distance = this.track.children[obj.currentIndex].position.distanceTo(this.track.children[obj.nextIndex].position);

    this.tweens[obj.object.name] = new Tween({
      posX : this.track.children[obj.currentIndex].position.x,
      posY : this.track.children[obj.currentIndex].position.y,
      posZ : this.track.children[obj.currentIndex].position.z,
    })
    .to({
      posX : this.track.children[obj.nextIndex].position.x,
      posY : this.track.children[obj.nextIndex].position.y,
      posZ : this.track.children[obj.nextIndex].position.z,
    }, distance * 500)
    .easing(Easing.Linear.None)
    .onUpdate((v) => {

      obj.object.position.set(v.posX, v.posY,v.posZ);
      obj.object.position.sub(this.track.position.clone());

    }).onComplete(()=>{
      obj.currentIndex = obj.nextIndex;
      obj.nextIndex = obj.nextIndex + 1 < this.track.children.length ? obj.nextIndex + 1 : 0;
      var vector3 = new Vector3();
      this.track.children[obj.nextIndex].getWorldPosition(vector3);
      obj.object.lookAt(vector3);
      this.MoveSingleObject(obj);
      this.tweens[obj.object.name].start();
    });


  }

  StopAnimation(){
    this.canAnimate = false;
    Object.keys(this.tweens).map((tweenName)=>{
      this.tweens[tweenName].stop();
    });

    Object.keys(this.selections).map((selectionName)=>{
      if(typeof this.selections[selectionName] == "undefined" ){return;}
      this.selections[selectionName].isClickEnabled = false;
    });
  }
  StartAnimation(){
    this.canAnimate = true;
    this.time = 0;
    Object.keys(this.tweens).map((tweenName)=>{
      this.tweens[tweenName].start();
    });

    Object.keys(this.selections).map((selectionName)=>{
      if(typeof this.selections[selectionName] == "undefined" ){return;}
      this.selections[selectionName].isClickEnabled = true;
    })
  }

  Animate = (t) => {

    if(!this.canAnimate){return;}

    this.Xr.Renderer.instance.shadowMap.needsUpdate = true;
    this.time ++;
  }
}

export default RoomTinyCity;
