import Points from './Points';
import TWEEN from '@tweenjs/tween.js';
import { MeshBasicMaterial, Vector3 } from 'three';
import SeasonController from './SeasonController';

import POIController from './POIController';
import AkkuController from './AkkuController';
import mainConfig from '../../main.config';


class SceneController {

  constructor(context) {

    this.xr = context.xr;
    this.store = context.store;
    this.router = context.router;
    this.currentPosition = 0;
    this.isInTransition = false;



    this.Init();
  }

  Init = () => {

    /*this.seasonController = new SeasonController({
      xr: this.xr,
      store: this.store
    });*/

    // this.poiController = new POIController({
    //   xr : this.xr,
    //   store : this.store
    // })


    this.AkkuController = new AkkuController({
      xr: this.xr,
      store: this.store,
    });


    /*const pi = 3.14159265359;
   this.store.watch(state => state.area.sunPosition, (newValue, oldValue) => {
      if (this.xr.Scene.sun != null) {
        this.xr.Scene.sun.SetSun(newValue * pi * 2);
      }
    });*/
  }

  SetPosition = (_point) => {


    console.log("SetPosition SceneController", _point);
    if (this.isInTransition || mainConfig.NoTransition || typeof(_point.position) == "undefined") { return; }
    this.store.commit("SetAllClicksDisabled", true)

    var xrMode = this.router.currentRoute.params.xrMode;// this.store.state.xrMode;// this.xr.Controls.GetCurrentXRMode();
    var { position, target } = _point;
    var scale = new Vector3(1, 1, 1);

    if (xrMode == "VR") {
      
        position = _point.VR_Position;
        target = _point.VR_Target;
        scale = _point.VR_Scale;

        console.log("SceneController " , this.store.state.content.currentSubTab, this.store.state.viewMode);
        if(this.store.state.content.currentSubTab != null && this.store.state.viewMode == 'explore'){
          position = this.store.state.content.currentSubTab.subtabVR_Position;
          target = this.store.state.content.currentSubTab.subtabVR_Target;
          scale = this.store.state.content.currentSubTab.subtabVR_Scale;

          console.log("currentSubTab position  " , position, target, scale);
        }
      //   console.log("SetPosition" , _point, this.router.currentRoute.params.area);
      // console.log(xrMode, "SetPosition VR", position);
      //return;
    } else {
      this.xr.Controls.ChangeToDefault();

      console.log("ChangeTODefault");
    }


    var cameraPos = this.xr.Controls.GetCameraPosition();
    var cameraTarget = this.xr.Controls.GetTarget();

    if (xrMode == "VR") {
      cameraPos = this.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].position.clone();
      cameraTarget = this.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].rotation.clone();
    }

    var start = {
      x: cameraPos.x,
      y: cameraPos.y,
      z: cameraPos.z,
      targetX: cameraTarget.x,
      targetY: cameraTarget.y,
      targetZ: cameraTarget.z,

      //SunAngle: this.store.state.content.currentViewContent.SunAngle,
      SunPosition: this.store.state.content.currentViewContent.SunPosition,

      // FOV_aperture: this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.aperture.value,
      // FOV_focus: this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.focus.value,
      // FOV_maxblur: this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.maxblur.value
    }




    var end = {
      x: position.x,
      y: position.y,
      z: position.z,
      targetX: target.x,
      targetY: target.y,
      targetZ: target.z,

      SunAngle: _point.SunAngle,
      SunPosition: _point.SunPosition,

      // FOV_aperture: _point.postprocessing.FOV_aperture,
      // FOV_focus: _point.postprocessing.FOV_focus,
      // FOV_maxblur: _point.postprocessing.FOV_maxblur,

    }


    if (this.store.state.world.mainScene.xr.Renderer.postprocessing.enabled) {
      start = Object.assign({
        FOV_aperture: this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.aperture.value,
        FOV_focus: this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.focus.value,
        FOV_maxblur: this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.maxblur.value
      }, start)


      end = Object.assign({
        FOV_aperture: _point.postProcessing.FOV_aperture,
        FOV_focus: _point.postProcessing.FOV_focus,
        FOV_maxblur: _point.postProcessing.FOV_maxblur,
      }, end)

    }


    if (xrMode == "VR") {
      start = Object.assign({
        scaleX: this.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].scale.x,
        scaleY: this.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].scale.y,
        scaleZ: this.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].scale.z,
      }, start)
      end = Object.assign({
        scaleX: scale.x,
        scaleY: scale.y,
        scaleZ: scale.z,
      }, end)
    }

    this.xr.Events.dispatchEvent("OnPOIChange", _point);


    if (xrMode == "Desktop") {
      this.xr.Controls.ChangeToDefault();
    }



    console.log("target" , end);

    // console.log("%c Set Position => SceneController", "background:#aaa;color:#fff");

    var tween = new TWEEN.Tween(start) // Create a new tween that modifies 'coords'.
      .to(end, 1000) // Move to (300, 200) in 1 second.
      .easing(TWEEN.Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
      .onUpdate((current) => {

        if (xrMode == "VR") {

          //console.log("SceneGroup " , this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene]);

          this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].position.x = current.x;
          this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].position.y = current.y;
          this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].position.z = current.z;

          this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].rotation.x = 0;
          this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].rotation.y = current.targetY;
          this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].rotation.z = 0;

          if (current.hasOwnProperty("scaleX")) {

            this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].scale.x = current.scaleX;
            this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].scale.y = current.scaleY;
            this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].scale.z = current.scaleZ;
          }

          this.store.state.world.mainScene.UpdateShadows();


        } else {
          this.xr.Controls.SetPosition(current.x, current.y, current.z);
          this.xr.Controls.SetTarget(current.targetX, current.targetY, current.targetZ);
        }


        if (this.store.state.world.mainScene.xr.Renderer.postprocessing.enabled) {
          this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.aperture.value = current.FOV_aperture;
          this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.focus.value = current.FOV_focus;
          this.store.state.world.mainScene.xr.Renderer.postprocessing.bokehPass.uniforms.maxblur.value = current.FOV_maxblur;
        }

        if (this.store.state.world.mainScene.xr.Scene.name === "House") {
          //this.store.state.world.mainScene.rooms.House.Sun.SetSunAngle(current.SunAngle);
          //this.store.state.world.mainScene.rooms.House.Sun.SetSun(current.SunPosition);
        }



      }).onComplete(() => {
        console.log(xrMode, this.store.state.content.currentSubTab != null)
        if(xrMode != "VR" && this.store.state.content.currentSubTab != null && this.store.state.viewMode == 'explore'){
          var targetCamPos = this.store.state.content.currentSubTab.subtabPosition;
          var targetCamTarget = this.store.state.content.currentSubTab.subtabTarget;

          console.log("currentSubTab from SetPosition" , this.store.state.content.currentSubTab, targetCamPos, targetCamTarget)
          this.store.state.world.mainScene.xr.Controls.SetPosition(targetCamPos.x,targetCamPos.y,targetCamPos.z);
          this.store.state.world.mainScene.xr.Controls.SetTarget(targetCamTarget.x,targetCamTarget.y,targetCamTarget.z);


          console.log(this.store.state.world.mainScene);
          this.store.state.world.mainScene.sprungMarkenController.SetVisible(false);
          
        }else if(this.store.state.viewMode == 'explore'){
          this.store.state.world.mainScene.sprungMarkenController.SetVisible(true);

        }
        
        if (xrMode == "desktop" && mainConfig.changeControlsToStatic) {
          this.xr.Controls.ChangeToStatic();

          console.log("ChangeToStatic");

        }

        this.isInTransition = false;
        this.store.commit("SetAllClicksDisabled", false)
      }).start();

    this.isInTransition = true;
  }


}

export default SceneController;