import IRoom from "./IRoom";
import {AmbientLight, DirectionalLight,HemisphereLight} from 'three';

class RoomUndergroundGarage implements IRoom {
    Name: String;
    Xr: any;
    ModelStack: Array<any>;

    isLoading: boolean = true;
    constructor(xr: any) {
        this.Name = 'UndergroundGarage';
        this.Xr = xr;
        this.ModelStack = [];
        this.Init();
    }
 

    Init(): void {

      
      const light = new AmbientLight( 0xffffff, 1 );
      this.Xr.SceneController.AddToScene(this.Name,light);
      
      var directionalLight = new DirectionalLight(0xffffff, 1);
          directionalLight.castShadow = true;
          //Set up shadow properties for the light
          directionalLight.shadow.mapSize.width = 1024; // default
          directionalLight.shadow.mapSize.height = 1024; // default
          directionalLight.shadow.camera.near = .1; // default
          directionalLight.shadow.camera.far = 70; // default
          const d = 20;

          directionalLight.shadow.camera.left = - d;
          directionalLight.shadow.camera.right = d;
          directionalLight.shadow.camera.top = d;
          directionalLight.shadow.camera.bottom = - d;
          
          directionalLight.shadow.bias = -0.01; // default
          directionalLight.position.set(20,30,20);
       this.Xr.SceneController.AddToScene(this.Name,directionalLight);    


    const hemiLight = new HemisphereLight( 0xffffff, 0x080820, 1 );
    this.Xr.SceneController.AddToScene(this.Name, hemiLight);
      
      //console.log("Room %c Kitchen ist geladen" , "background:#ff9800; color:#fff;");
    }

    SetModelStack(stack: any, maps:any, lightMap:any){
        var mapForRoom = maps.filter(map => { return map.room == this.Name })[0];
        var lightMapForRoom = lightMap.filter(map => { return map.room == this.Name })[0];

        Object.values(stack).map((model:any) => {
            if(model.scene.userData.Room == this.Name){
                //AoMap
                if (mapForRoom  != null && mapForRoom != undefined) {
                    model.scene.traverse((child) => {
                        if (child.material != undefined) {
                            child.material.aoMap = mapForRoom.texture;
                        }
                    })
                }
                
                if (lightMapForRoom  != null && lightMapForRoom != undefined) {
                    model.scene.traverse((child) => {
                        if (child.material != undefined) {
                            child.material.lightMap = lightMapForRoom.texture;
                            child.material.lightMapIntensity = 1;
                        }
                    })
                }


                model.scene.traverse(child => {
                  if(child.type == "Mesh" || child.type == "SkinnedMesh"){
                    child.castShadow = true;
                    child.receiveShadow = true;
                  }
                  
                    //Wallbox
                    if(child.name == "tesla3_blender007" || child.name == "tesla3_blender008"){
                      for(var i=0;i<4;i++){
                        let childCopy = child.clone();
                        childCopy.position.z += 2.75 * i;

                        if(i == 2 && child.name == "tesla3_blender007"){
                          childCopy.children[0].visible = false;
                        }
                        
                        if(i == 2 && child.name == "tesla3_blender008"){continue;}
                        this.Xr.SceneController.AddToScene(this.Name,childCopy);
                        
                      }
                      
                      for(var i=0;i<4;i++){
                        let childCopy = child.clone();
                        childCopy.position.z += (2.75 * i) + 11.35;
                        if(i == 1 && child.name == "tesla3_blender007"){
                          childCopy.children[0].visible = false;
                          childCopy.position.z -= .15;
                        }

                        if(i == 1 && child.name == "tesla3_blender008"){continue;}
                        this.Xr.SceneController.AddToScene(this.Name,childCopy);
                      }
                    }
                  })


                this.ModelStack.push(model);
                this.Xr.SceneController.AddToScene(this.Name,model.scene)
            }
        })
    }


    Detach(arg: any): void {
        throw new Error("Method not implemented.");
    }
    Attach(arg: any): void {
        throw new Error("Method not implemented.");
    }
}

export default RoomUndergroundGarage;