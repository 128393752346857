import IRoom from "./IRoom";
import {AmbientLight, DirectionalLight} from 'three';

class RoomInstallations implements IRoom {
  Name : String;
  Xr : any;
  ModelStack : Array < any >;

  isLoading : boolean = true;
  constructor(xr:any) {
    this.Name = 'InstallationsRoom';
    this.Xr = xr;
    this.ModelStack = [];
    this.Init();
  }


  Init(): void {


    const light = new AmbientLight(0xffffff, 1);
    this.Xr.SceneController.AddToScene(this.Name, light);

    var directionalLight = new DirectionalLight(0xffffff, 1.5);
    directionalLight.castShadow = true;
    // Set up shadow properties for the light
    directionalLight.shadow.mapSize.width = 1024; // default
    directionalLight.shadow.mapSize.height = 1024; // default
    directionalLight.shadow.camera.near = .1; // default
    directionalLight.shadow.camera.far = 70; // default
    directionalLight.shadow.bias = -0.01; // default
    directionalLight.position.set(10, 20, 10);
    this.Xr.SceneController.AddToScene(this.Name, directionalLight);

    // console.log("Room %c Kitchen ist geladen" , "background:#ff9800; color:#fff;");
  }

  SetModelStack(stack:any, maps:any, lightMaps:any) {
    var mapForRoom = maps.filter(map => {
        return map.room == this.Name
      })[0];
      var lighmapForRoom = lightMaps.filter(map => {
        return map.room == this.Name
      })[0];

      Object.values(stack).map((model : any) => {
        if (model.scene.userData.Room == this.Name) { // AoMap
          model.scene.traverse((child) => {
            if (child.material != undefined) {
              if (mapForRoom != null && mapForRoom != undefined) {
                child.material.aoMap = mapForRoom.texture;
                child.material.aoMapIntensity = .25;

                console.log("Apply AO Installationsroom");
              }


              if (lighmapForRoom != null && lighmapForRoom != undefined) {
                child.material.lightMap = lighmapForRoom.texture;
                child.material.lightMapIntensity = 1.25;
              }


            }
          })
        }
        this.ModelStack.push(model);
        this.Xr.SceneController.AddToScene(this.Name, model.scene)
      });
    }


  Detach(arg:any):void {
    throw new Error("Method not implemented.");
  }
  Attach(arg:any):void {
    throw new Error("Method not implemented.");
  }
}

export default RoomInstallations;
