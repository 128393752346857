import IRoom from "./IRoom";
import {AmbientLight, DirectionalLight,HemisphereLight} from 'three';

class RoomBalcony implements IRoom {
    Name: String;
    Xr: any;
    ModelStack: Array<any>;

    isLoading: boolean = true;
    constructor(xr: any) {
        this.Name = 'Balcony';
        this.Xr = xr;
        this.ModelStack = [];
        this.Init();
    }
 

    Init(): void {

      
      const light = new AmbientLight( 0xffffff, 2.2 );
      this.Xr.SceneController.AddToScene(this.Name,light);
      
      //console.log("Room %c Kitchen ist geladen" , "background:#ff9800; color:#fff;");
    }

    SetModelStack(stack: any, maps:any,lightMap:any){
        var mapForRoom = maps.filter(map => { return map.room == this.Name })[0];
        var lightMapForRoom = lightMap.filter(map => { return map.room == this.Name })[0];

        Object.values(stack).map((model:any) => {
            if(model.scene.userData.Room == this.Name){
                //AoMap
                if (mapForRoom  != null && mapForRoom != undefined) {
                    model.scene.traverse((child) => {
                        if (child.material != undefined) {
                            child.material.aoMap = mapForRoom.texture;
                            child.material.aoMapIntensity = .4;// mapForRoom.texture;
                        }
                    })
                }
                
                if (lightMapForRoom  != null && lightMapForRoom != undefined) {
                    model.scene.traverse((child) => {
                        if (child.material != undefined) {
                            child.material.lightMap = lightMapForRoom.texture;
                            //child.material.lightMapIntensity = .2;
                        }
                    })
                }

                model.scene.traverse((child) => {
                  if (child.type == "SkinnedMesh") {
                    child.frustumCulled = false;
                  }

                  if(child.material?.name == "Metal"){
                    child.material.metalness = 0.5;
                    child.material.roughness = 0.7;
                  }
                  
                });


                this.ModelStack.push(model);
                this.Xr.SceneController.AddToScene(this.Name,model.scene)
            }
        })
    }


    Detach(arg: any): void {
        throw new Error("Method not implemented.");
    }
    Attach(arg: any): void {
        throw new Error("Method not implemented.");
    }
}

export default RoomBalcony;