import IRoom from "./IRoom";
import Sonne from "../Sonne";
import SnowController from "./SnowController";
import AutumnParticlesController from "./AutumnParticlesController";

import Sky from "../../Scene/Sky";

import {LinearEncoding, Color,Vector2} from 'three';

class RoomHouse implements IRoom {
    Name: String;
    Xr: any;
    ModelStack: Array<any>;
    Sun: any;
    Store: any;
    SnowController: any;
    AutumnParticlesController: any;
    Skybox: any;
    Sky : any;

    isLoading: boolean = true;
    constructor(xr: any, store: any) {
        this.Name = 'House';
        this.Store = store
        this.Xr = xr;
        this.ModelStack = [];
        this.Init();
        this.Skybox = null;
    }


    Init(): void {

        this.SnowController = new SnowController({
            xr: this.Xr,
            store: this.Store
        });
        this.Xr.SceneController.AddToScene(this.Name, this.SnowController);

        this.AutumnParticlesController = new AutumnParticlesController({
            xr: this.Xr,
            store: this.Store,
        });
        this.Xr.SceneController.AddToScene(this.Name, this.AutumnParticlesController);

        this.Sun = new Sonne(this.Store, this.Xr);

        this.Xr.SceneController.AddToScene(this.Name, this.Sun.sun);
        
        // this.Sky = new Sky({xr : this.Xr, store: this.Store});

        // this.Xr.Scene.environment = this.Sky.skyTexture;

    }
    PrepareStack(stack){

     
      Object.keys(stack).map(stackName => {
        let scene = stack[stackName].scene;

        scene.traverse((obj) => {

          //Bärchenfix
          if(stackName == "House"){
            if (obj.name == "Sphere012") {
              obj.userData = {
                ViewMode: "explore"
              };
              obj.name = "Bärchen";
            }
          }

          if (obj.type == "SkinnedMesh" || obj.type == "Mesh") {
            if (obj.material.name.includes("Solar") || obj.material.name.includes("Solarpanel") || obj.material.name.includes("Plane_baked") || obj.material.name.includes("Solarpanel_Flex")) {
              
              //obj.material.metalness = .7;
              obj.material.normalScale = new Vector2(.3,.3);
              // obj.material.roughness = .7;
              // obj.material.roughnessMap = null;
            }
          }


          // Disable Culling for skinned Meshes
          if (obj.type == "SkinnedMesh") {
            obj.frustumCulled = false;
            obj.castShadow = true;
            obj.receiveShadow = true;
          }

          if (obj.type == "SkinnedMesh"|| obj.type == "Mesh") {
            obj.castShadow = true;
            obj.receiveShadow = true;
          }

          // Fix Tree Texture
          if (obj.type == "Mesh") {

            if (obj.material.name === "Flowers") {
              obj.castShadow = true;
            }

            if (obj.material.name === "Tree" && obj.material.map != null) {
              obj.material.map.premultipliedAlpha = false;
              obj.material.map.encoding = LinearEncoding;
              obj.material.map.needsUpdate = true;
              obj.material.needsUpdate = true;
              obj.material.aoMap = null;
            }
          }


          if (obj.hasOwnProperty("material")) {
            if (obj.material.name == "Alu") {
              obj.material.roughness = .4;
              obj.material.color = new Color(0xcccccc);
            }
            
            if (obj.material.name == "Roof") {
              obj.material.color = new Color(0xffffff);
            }

            if (obj.material.color != null) {
              obj.material.color = obj.material.color.convertSRGBToLinear();
            }
          }


        });
      });

      this.Store.state.world.mainScene.dashboardController.AddObjects(stack.Stromkabel, 'House');
    }
    SetModelStack(stack, maps) {

      this.PrepareStack(stack);
        var mapsForRoom = maps.filter(map => { return map.room == this.Name })
        Object.values(stack).map((model: any) => {

            if (model.scene.userData.Room == this.Name) {
                //console.log("HOUSE MODEL TRY FIND AOMAP", model.name);

                //AoMap
                var map = mapsForRoom.find(map => { return map.name == model.name });
                if (map != null && map != undefined) {
                    //console.log("FOUND MAP FOR ", model);

                    model.scene.traverse((child) => {
                        if (child.material != undefined) {
                            child.material.aoMap = map.texture;
                        }
                    })
                }

                this.ModelStack.push(model)
                this.Xr.SceneController.AddToScene(this.Name, model.scene)
            }
        })
    }

    Detach(arg: any): void {
        throw new Error("Method not implemented.");
    }
    Attach(arg: any): void {
        throw new Error("Method not implemented.");
    }
}

export default RoomHouse;