import { MeshStandardMaterial, MeshBasicMaterial, Color } from 'three'


class SunRayMaterial {
  constructor(mesh) {

    this.sun_ray_material = new MeshStandardMaterial({
      //skinning: true,
      transparent: true,
      side: 2,
      toneMapped:false,
      name: "SunRayMaterial"
    });

    this.sun_ray_material.onBeforeCompile = shader => {
      shader.uniforms.time = { value: 1. };
      shader.uniforms.color = { value: new Color(0xF9C697) }
      shader.uniforms.custom_opacity = { value: 0 }

      shader.vertexShader = 'varying vec2 vUv;\n' + shader.vertexShader;

      shader.vertexShader = shader.vertexShader.replace(
        '#include <begin_vertex>',
        ['#include <begin_vertex>','vUv = uv;'].join('\n')
      );

      shader.fragmentShader = 'uniform float time;\n uniform vec3 color;\n uniform float custom_opacity;\n varying vec2 vUv;\n' + shader.fragmentShader;
      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <dithering_fragment>',
        [
          '#include <dithering_fragment>',
          //'gl_FragColor = vec4( color , custom_opacity);'
          'float visible = 0.;',
          'float length = .4;',
          'if(vUv.x > (1.-time) && vUv.x < (1.-time+length)) {visible = ((1. - vUv.x) - (time - length)) / length;}',
          'gl_FragColor = vec4( color , visible * custom_opacity);'
        ].join('\n'));

      this.sun_ray_material.shader = shader;
      console.log("ON BEFORE COMPILE ",this.sun_ray_material)
    };
    return this.sun_ray_material

  }

}

export default SunRayMaterial;