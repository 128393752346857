import {
    AnimationClip,
    NumberKeyframeTrack,
    ColorKeyframeTrack,
    AnimationMixer,
    ShaderMaterial,
    Color, EXRLoader, MeshBasicMaterial,Geometry, Group, Object3D, Mesh,Vector2, Vector3, EquirectangularReflectionMapping, BackSide, MeshStandardMaterial
} from 'three';

import SunRayMaterial from "./SunRayMaterial";
import EnvMap from "../../../models/studio_small_03_1k.jpg";
import EnergyParticles from './EnergyParticles';
import { MeshLine, MeshLineMaterial } from '../../meshline';

import ThreeMeshUI from 'three-mesh-ui';
import ScreenConstants from '../../ScreenComponents/ScreenConstants'
import FontBoldImage from '../../../Fonts/Roboto-Bold.png'
import FontBoldJSON from '../../../Fonts/Roboto-Bold-msdf.json'

import Utility from '@/utils';


class SolarzellenController {
    xr: any;
    store: any;
    frameRate: number = 30;
    mesh: any = null;
    electricity: any = null;
    plus: any = null;
    minus: any = null;
    wechselrichter: any = null;
    sun_ray: any = null;
    glass: any = null;
    back: any = null;
    panel_frame: any = null;
    cells: any = null;
    cell_grid_flat: any = null;
    cell_n_type: any = null;
    cell_n_type_color: Color = null;
    cell_p_type: any = null;
    cell_p_type_color: Color = null;
    env_map: any = null;
    RedParticles: any = null;
    BlueParticles: any = null;
    cell_depletion: any = null;
    notations:any = [];

    initialized: boolean = false;
    mixer: any = null;
    action: any = null;
    animationClip: any = null;


    dashedLine: any = null;
    kabel: any = null;


    constructor(props) {
        this.xr = props.xr;
        this.store = props.store;
        this.Setup();
    }

    Setup() {
        const FadeOutOpacity = [1, 1, 0, 0]

        //OTHERS
        const glassTrack = new NumberKeyframeTrack(
            'Glass_1.material.opacity',
            [0, 40. / 30., 60. / 30., 500. / 30.],
            FadeOutOpacity,
        );
        const backTrack = new NumberKeyframeTrack(
            'Back_1.material.opacity',
            [0, 60 / 30, 80 / 30, 500 / 30],
            FadeOutOpacity,
        );
        const panelTrack = new NumberKeyframeTrack(
            'Panel_Frame.material.opacity',
            [0, 80 / 30, 100 / 30, 500 / 30],
            FadeOutOpacity,
        );
        const cellsTrack = new NumberKeyframeTrack(
            'Cells_1.material.opacity',
            [0, 100 / 30, 120 / 30, 500 / 30],
            FadeOutOpacity,
        );
        const cellsGridTrack = new NumberKeyframeTrack(
            'Cell_Grid_Flat.material.opacity',
            [0, 100 / 30, 120 / 30, 500 / 30],
            FadeOutOpacity,
        );
        this.animationClip = new AnimationClip('opacityIcons', 500 / 30, [
            glassTrack, backTrack, panelTrack, cellsTrack, cellsGridTrack
        ]);

        if (this.store.state.world.sceneReady) {
            this.SetUpMixer();
        }

        this.store.watch(state => state.animation.isPlaying, (newValue, oldValue) => {
            if (this.store.state.content.currentPOI == null) { return }
            if (newValue && this.store.state.content.currentPOI.id == 8) {
                this.action.play()
            }
        })

        this.store.watch(state => state.content.currentPOI, (newValue, oldValue) => {
            if (newValue == null) {
                return;
            }
            if (newValue.id == 8) {
                this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);
                this.xr.Events.addEventListener("OnResetAnimationController", this.Reset);
                this.Reset()
            } else {
                this.OnAnimationStoped();
                this.xr.Events.removeEventListener("OnResetAnimationController", this.Reset);
                this.Reset();
            }
        })

        this.store.watch(state => state.world.roomProgress, (newValue, oldValue) => {
            
          if(!newValue.Solarzelle.isLoading && Object.prototype.hasOwnProperty.call(this.store.state.world.Library, "Solarzelle")){
            this.SetUpMixer();
          }
        });

    }

    SetUpMixer() {

      if(this.initialized){return;}
      this.initialized = true;

        this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);

        this.mesh = this.store.state.world.Library.Solarzelle;

        this.mesh.scene.position.set(0, 0, 1)

        this.mixer = new AnimationMixer(this.mesh.scene.children[0]);

        if (this.mixer != null) {
            this.action = this.mixer.clipAction(this.animationClip);
        }
        this.env_map = this.xr.Scene.sky?.rt.texture;


        this.mesh.scene.traverse(m => {
            if (m.name == "Wechselrichter") {
                this.wechselrichter = m;
                this.wechselrichter.renderOrder = 99;
                this.wechselrichter.children[0].material = new MeshBasicMaterial({ envMap: null, transparent: true, reflectivity: 0, toneMapped: false, color: new Color(0xF38D2F) });
                this.wechselrichter.children[1].material = new MeshBasicMaterial({ envMap: null, transparent: true, reflectivity: 0, toneMapped: false, color: new Color(1, 1, 1) });
                this.wechselrichter.rotation.set(0, Math.PI * 0.5, 0)
            }
            else if (m.name == "Electricity") {
                this.electricity = m;
                this.electricity.visible = false;
                this.SetupKabel();
            }
            else if (m.name == "Plus") {
                this.plus = m;
                this.plus.renderOrder = 99;
                this.plus.children[0].material = new MeshBasicMaterial({ reflectivity: 0, transparent: true, toneMapped: false, depthTest: false, color: new Color(0xa00d0f) });
                this.plus.children[1].material = new MeshBasicMaterial({ reflectivity: 0, transparent: true, toneMapped: false, depthTest: false, color: new Color(1, 1, 1) });
                this.plus.rotation.set(0, Math.PI * 0.5, 0)
            }
            else if (m.name == "Minus") {
                this.minus = m;
                this.minus.renderOrder = 99;
                this.minus.children[0].material = new MeshBasicMaterial({ reflectivity: 0, transparent: true, toneMapped: false, depthTest: false, color: new Color(0x090ccb) });
                this.minus.children[1].material = new MeshBasicMaterial({ reflectivity: 0, transparent: true, toneMapped: false, depthTest: false, color: new Color(1, 1, 1) });
                this.minus.rotation.set(0, Math.PI * 0.5, 0)

            } else if (m.name == "Sun_Ray") {
                this.sun_ray = m;
                this.sun_ray.material = new SunRayMaterial();
            }
            else if (m.name == "Glass_1") {
                this.glass = m;
                this.glass.material.transparent = true;
                this.glass.material.envMap = this.env_map;
                this.glass.material.ior = 1.5;
                this.glass.material.thickness = 2;
                this.glass.material.reflectivity = 0.7;
            } else if (m.name == "Back_1") {
                this.back = m;
                this.back.material.transparent = true;
                this.back.material.envMap = this.env_map;
            } else if (m.name == "Panel_Frame") {
                this.panel_frame = m;
                this.panel_frame.material = this.panel_frame.material.clone();
                this.panel_frame.material.transparent = true;
                this.panel_frame.material.envMap = this.env_map;
            } else if (m.name == "Cells_1") {
                this.cells = m;
                this.cells.material.transparent = true;
                this.cells.material.envMap = this.env_map;
            } else if (m.name == "Cell_Grid_Flat") {
                this.cell_grid_flat = m;
                this.cell_grid_flat.material = this.cell_grid_flat.material.clone();
                this.cell_grid_flat.material.envMap = this.env_map;
                this.cell_grid_flat.material.transparent = true;
            } else if (m.name == "Cell_Grid_Back") {
                m.material.side = 2;
            } else if (m.name == "Cell_Depletion") {
                this.cell_depletion = m;
                this.RedParticles = new EnergyParticles({ color: new Color(1, 0, 0), position: m.position, xr: this.xr, direction: -1 })
                this.BlueParticles = new EnergyParticles({ color: new Color(0, 0, 1), position: m.position, xr: this.xr, direction: 1 })
                this.cell_depletion.material.envMap = this.env_map;
                this.cell_depletion.material.ior = 1.5;
                this.cell_depletion.material.thickness = 2;
                this.cell_depletion.material.reflectivity = 0.7;
                m.add(this.RedParticles.group);
                m.add(this.BlueParticles.group);
            }
            else if (m.name == "Cell_Grid_Front") {
                m.material.side = 2;
            }
            else if (m.name == "Cell_N_Type") {
                this.cell_n_type = m;
                this.cell_n_type_color = m.material.color.clone();
                this.cell_n_type.material.envMap = this.env_map;
                this.cell_n_type.material.transparent = true;
            } else if (m.name == "Cell_P_Type") {
                this.cell_p_type = m;
                this.cell_p_type_color = m.material.color.clone();
                this.cell_p_type.material.envMap = this.env_map;
                this.cell_p_type.material.transparent = true;
            } else if(m.name == "b_metall_1"){
                this.notations.push(m);
                m.material.transparent = true;
            }

        });

        this.Reset();
    }

    SetupKabel() {
        var verts:any = Utility.GetVertices(this.electricity.geometry, 0.001);
        const line = new MeshLine();
        const vertices:number[] = [];
        verts?.map(v => {
          vertices.push(v.x,v.y,v.z);
        })



        const material = new MeshLineMaterial({
            color: new Color(0xF38D2F),
            lineWidth: .02,
            sizeAttenuation: 1,
            opacity: 0,
            resolution: new Vector2(128,128)
        });

        verts = verts?.map((v) => {
            var vert = v;
            vert.z = v.z + 0.99;

          
            return vert
        });


        line.setPoints(vertices);
        this.kabel = new Mesh(line, material);
        this.xr.SceneController.AddToScene("Solarzelle", this.kabel);

    
        line.setPoints(vertices);


        this.dashedLine = new Mesh(line, new MeshLineMaterial({
            color: new Color(0x0000FF),
            lineWidth: .012,
            sizeAttenuation: 1,
            dashArray: .015,
            dashRatio: 0.5,
            opacity: 0,
            resolution : new Vector2(128,128)
          
        }));
        this.dashedLine.renderOrder = 99;
        this.dashedLine.position.set(0.005,0,0)

        this.kabel.add(this.dashedLine);
    
        this.kabel.position.z = 1;
    }

    OnAnimationStoped = () => {
        this.xr.Events.removeEventListener("OnAnimationLoop", this.Animate);
    }

    Animate = (clock) => {
        
        //ThreeMeshUI.update();

        if (this.store.state.world.mainScene.AnimationController.mixer == null) { return; }
        var currentFrame = Math.round(this.store.state.world.mainScene.AnimationController.mixer.time * this.frameRate);
        
        this.action.syncWith(this.store.state.world.mainScene.AnimationController.clipAction)

        if (this.mixer != null && this.store.state.world.mainScene.AnimationController.isPlaying) {
            this.mixer.update(clock.getDelta());
        }

        if (currentFrame == 120) {
            this.SetBackgroundParts(false);
        }
        if (currentFrame == 250) {
            this.sun_ray.visible = true;
        }

        //SUN RAY
        if (currentFrame >= 250) {
            if (this.sun_ray.material.shader != undefined) {
                this.sun_ray.material.shader.uniforms.custom_opacity.value = 1.0
                this.sun_ray.material.shader.uniforms.time.value = (((currentFrame - 240) / 50) % 1) * 1.2;
                this.sun_ray.material.uniformsNeedUpdate = true;
            }
        }

        //PARTICLES
        if (currentFrame == 300) {
            //this.sun_ray.visible = false
            this.RedParticles.Start();
            this.BlueParticles.Start();
        }

        if (currentFrame >= 300 && currentFrame < 350) {
            var progress = ((currentFrame - 300) / 50)
            this.cell_n_type.material.color.lerpColors(this.cell_n_type_color, new Color(0, 0, 1), progress);
            this.cell_p_type.material.color.lerpColors(this.cell_p_type_color, new Color(1, 0, 0), progress);
        }

        if (currentFrame >= 320 && currentFrame < 340) {

            

            var progress = ((currentFrame - 320) / 20)
            this.dashedLine.material.opacity = Utility.lerp(0, 1, progress);
            this.kabel.visible = true;
            this.kabel.material.opacity = Utility.lerp(0, 1, progress);

            this.notations.forEach(element => {
                element.material.opacity = progress; 
             });

            this.plus.children[0].material.opacity = Utility.lerp(0, 1, progress);
            this.plus.children[1].material.opacity = Utility.lerp(0, 1, progress);

            this.minus.children[0].material.opacity = Utility.lerp(0, 1, progress);
            this.minus.children[1].material.opacity = Utility.lerp(0, 1, progress);

            this.wechselrichter.children[0].material.opacity = Utility.lerp(0, 1, progress);
            this.wechselrichter.children[1].material.opacity = Utility.lerp(0, 1, progress);
        }

        if (currentFrame >= 340) {
            this.dashedLine.material.uniforms.dashOffset.value -= 0.001;
            this.dashedLine.material.uniformsNeedUpdate = true;
        }
    }

    Reset = () =>{

        this.notations.forEach(element => {
           element.material.opacity = 0; 
        });

        this.plus.children[0].material.opacity = 0;
        this.minus.children[0].material.opacity = 0;
        this.wechselrichter.children[0].material.opacity = 0;

        this.plus.children[1].material.opacity = 0;
        this.minus.children[1].material.opacity = 0;
        this.wechselrichter.children[1].material.opacity = 0;

        if (this.sun_ray.material.shader != undefined) {
            this.sun_ray.material.shader.uniforms.custom_opacity.value = 0;
            this.sun_ray.material.uniformsNeedUpdate = true;
        }
        this.SetBackgroundParts(true);

        this.cell_n_type.material.color.set(this.cell_n_type_color);
        this.cell_p_type.material.color.set(this.cell_p_type_color);

        this.RedParticles.Stop();
        this.BlueParticles.Stop();

        this.dashedLine.material.opacity = 0;
        this.kabel.material.opacity = 0;

        this.sun_ray.visible = false;
        this.kabel.visible = false;
    }

    SetBackgroundParts(visible: boolean) {
        this.panel_frame.visible = visible;
        this.cells.material.visible = visible;
        this.cell_grid_flat.visible = visible;
        this.glass.material.visible = visible;
        this.back.material.visible = visible;
        if (visible) {
            this.panel_frame.material.opacity = 1;
            this.cells.material.opacity = 1;
            this.cell_grid_flat.material.opacity = 1;
            this.glass.material.opacity = 1;
            this.back.material.opacity = 1;
        }
    }

}

export default SolarzellenController;