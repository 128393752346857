import { Vector3, LinearFilter,PointsMaterial,BufferAttribute,MeshBasicMaterial, Points, Texture, FloatType, BufferGeometry ,DoubleSide,sRGBEncoding} from "three/build/three.module";
import SnowParticleTexture from '../../models/textures/snow_alpha.png';
class SnowController {
  constructor(props) {
    this.particleNum = 2000;
    this.maxRange = 100;
    this.minRange = this.maxRange / 2;
    this.textureSize = 32.0;

    this.box = {
      x: 50,
      y: 100,
      z: 50
    }

    this.xr = props.xr;
    this.store = props.store;

    this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);

    this.store.watch(state => state.area.season, (newSeason, oldSeason) => {
      this.mustUpdate = newSeason == "Winter";
      this.particles.visible = newSeason == "Winter";
    })
    return this.Init();
  }

  Init = () => {
    /* Snow Particles
    -------------------------------------------------------------*/
    const pointGeometry = new BufferGeometry();
    var verts = [];

    for (let i = 0; i < this.particleNum; i++) {
      const x = -this.box.x + Math.floor(Math.random() * (this.box.x * 2));
      const y = -this.box.y + Math.floor(Math.random() * (this.box.y * 2));
      const z = -this.box.z + Math.floor(Math.random() * (this.box.z * 2));

      verts.push(x,y,z);
    }

    var vertices = new Float32Array(verts)
    pointGeometry.setAttribute( 'position', new BufferAttribute( vertices, 3 ) );

    const velocities = [];
    for (let i = 0; i < this.particleNum; i++) {
      const x = Math.floor(Math.random() * 6 - 3) * 0.01;
      const y = Math.floor(Math.random() * 10 + 3) * - 0.05;
      const z = Math.floor(Math.random() * 6 - 3) * 0.01;
      velocities.push(x,y,z);
    }


    this.pointMaterial = new PointsMaterial({
      size: 1,
      color: 0xffffff,
      side: DoubleSide,
      //map: this.GetSnowFlakeTexture(),
      transparent: true,
      //depthTest : false,
    });
    this.pointMaterial.name = "SnowMaterial";
    this.xr.CustomTextureLoader.load(SnowParticleTexture).then((texture)=>{
      this.pointMaterial.map = texture;

    })
    


    this.particles = new Points(pointGeometry, this.pointMaterial);
    this.particles.geometry.velocities = velocities;
    this.particles.visible = false;
    this.particles.name = "Snow";
    //this.xr.Scene.add(this.particles);
    return this.particles;
  }

  GetSnowFlakeTexture = () => {

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const diameter = this.textureSize;
    canvas.width = diameter;
    canvas.height = diameter;
    const canvasRadius = diameter / 2;

    /* gradation circle
    ------------------------ */
    this.drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);

    /* snow crystal
    ------------------------ */
    // drawSnowCrystal(ctx, canvasRadius);

    const texture = new Texture(canvas);
    //texture.minFilter = THREE.NearestFilter;
    texture.encoding =  sRGBEncoding;
    texture.magFilter = texture.minFilter = LinearFilter;
    texture.needsUpdate = true;
    return texture;
  }
  drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
    ctx.save();
    const gradient = ctx.createRadialGradient(canvasRadius, canvasRadius, 0, canvasRadius, canvasRadius, canvasRadius);
    gradient.addColorStop(0, 'rgba(255,255,255,1.0)');
    gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
    gradient.addColorStop(1, 'rgba(255,255,255,0)');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvasW, canvasH);
    ctx.restore();
  }

  Animate = (t) => {
    if (!this.mustUpdate) { return; }

    const pos = this.particles.geometry.attributes.position.array;
    const velArr = this.particles.geometry.velocities;

    for(var i=0;i<pos.length; i+=3){

      const velocity = {
        x : velArr[i],
        y : velArr[i + 1],
        z : velArr[i + 2]
      }

      // y
      pos[i + 1] += velocity.y;

      if (pos[i + 1] < -this.box.y) {
        pos[i + 1] = this.box.y;
      }
    }
    this.particles.geometry.attributes.position.needsUpdate = true;
  }

}


export default SnowController;