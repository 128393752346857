import { BoxGeometry, Mesh, BufferAttribute, Vector3, MeshStandardMaterial, LinearFilter, PointsMaterial, MeshBasicMaterial, Points, Texture, FloatType, BufferGeometry, DoubleSide, sRGBEncoding, Color } from "three/build/three.module";
import SnowParticleTexture from '../../../models/textures/snow_alpha.png';
class EnergyParticles {
    color: Color;
    xr: any;
    group: any
    particleNum: number;
    box: any;
    pointMaterial: any;
    particles: any;
    direction: any;

    constructor(props) {
        this.color = props.color;
        this.xr = props.xr
        this.particleNum = 200;
        this.direction = props.direction;
        this.box = {
            x: 0.4,
            y: 0.2,
            z: 0.4,
            startOffset: 0.04,
            startY :-0.04
            
        }

        this.Init();
    }

    Init = () => {
        const geometry = new BoxGeometry(1, 0.5, 1);
        const material = new MeshBasicMaterial({ color: this.color, transparent: true, opacity: 0 });

        this.group = new Mesh(geometry, material);
        this.group.rotation.set(0,Math.PI/4,0);
        this.group.visible = false

        //PARTICLES
        const pointGeometry = new BufferGeometry();
        var verts:number[] = [];

        for (let i = 0; i < this.particleNum; i++) {
            const x:number = -this.box.x + Math.random() * (this.box.x * 2);
            const y:number =  this.box.startY + this.direction*this.box.startOffset; //this.box.y
            const z:number = -this.box.z + Math.random() * (this.box.z * 2);

            verts.push(x, y, z);
        }

        var vertices = new Float32Array(verts)
        pointGeometry.setAttribute('position', new BufferAttribute(vertices, 3));

        const velocities:number[] = [];
        for (let i = 0; i < this.particleNum; i++) {
            const x:number = 0     //0.1;    //0//Math.floor(Math.random() * 6 - 3) * 0.01;
            const y:number = (0.001+Math.random() * 0.002) * this.direction;
            const z:number = 0     //0.1;    // 0//Math.floor(Math.random() * 6 - 3) * 0.01;
            velocities.push(x, y, z);
        }

        this.pointMaterial = new PointsMaterial({
            size: 0.03,
            color: this.color,
            side: DoubleSide,
            //map: this.GetSnowFlakeTexture(),
            transparent: true,
            depthTest: false,
            //depthWrite:true
        });

        this.xr.CustomTextureLoader.load(SnowParticleTexture).then((texture) => {
            this.pointMaterial.map = texture;

        })

        this.particles = new Points(pointGeometry, this.pointMaterial);
        this.particles.renderOrder = 99;
        this.particles.geometry.velocities = velocities;
        this.particles.visible = true;
        this.group.add(this.particles);
        return this.particles;

    }



    Animate = (t) => {
        if (!this.group.visible) { return; }

        const pos = this.particles.geometry.attributes.position.array;
        const velArr = this.particles.geometry.velocities;

        for (var i = 0; i < pos.length; i += 3) {

            const velocity = {
                x: velArr[i],
                y: velArr[i + 1],
                z: velArr[i + 2]
            }

            // y
            pos[i + 1] += velocity.y;

            if (Math.abs(pos[i + 1]) > this.box.y) {
                pos[i + 1] = this.box.startY + this.direction*this.box.startOffset
            }
        }
        this.particles.geometry.attributes.position.needsUpdate = true;
    }


    Start = () => {
        this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);
        this.group.visible = true;

        const pos = this.particles.geometry.attributes.position.array;
        for (var i = 0; i < pos.length; i += 3) {
            pos[i + 1] = this.direction*this.box.startOffset
        }
        this.particles.geometry.attributes.position.needsUpdate = true;
    }

    Stop = () => {
        this.xr.Events.removeEventListener("OnAnimationLoop", this.Animate);
        this.group.visible = false;
    }

}

export default EnergyParticles