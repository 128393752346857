import Utility from "@/utils";
import { Vector3, PointsMaterial, Points, Texture, FloatType, BufferGeometry, Group, BufferAttribute, ShaderMaterial, Color } from "three/build/three.module";
import SeasonLeavesColors from '../Materials/SeasonLeavesColors';
import leaveTex from '../../models/textures/leave.png';

const vertexShader = `attribute float size;
attribute vec3 customColor;

varying vec3 vColor;

void main() {

  vColor = customColor;

  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

  gl_PointSize = size * ( 300.0 / -mvPosition.z );

  gl_Position = projectionMatrix * mvPosition;

}`;

const fragmentShader = `uniform vec3 color;
uniform sampler2D pointTexture;

varying vec3 vColor;

void main() {

  gl_FragColor = vec4( color * vColor, 1.0 );
  gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );

}`

class AutumnParticlesController {
  constructor(props) {

    this.holder = new Group();
    this.particleNum = 20;
    this.maxRange = 20;
    this.minRange = this.maxRange / 2;
    this.textureSize = 32.0;
    this.colors = SeasonLeavesColors;
    this.leavesTexture = null;

    this.box = {
      x: 2.5,
      y: 3.5,
      z: 2.5
    }

    this.xr = props.xr;
    this.store = props.store;

    this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);

    this.store.watch(state => state.area.season, (newSeason, oldSeason) => {
      var boolean = newSeason == "Autumn" && this.store.state.area.shadow == "Shadow";
      this.mustUpdate = boolean;
      this.holder.visible = boolean;
    });

    this.store.watch(state => state.area.current, (newArea) => {
      if (newArea == null) {
        return;
      }
      switch (newArea.routeParam) {
        case 'familyhouse': this.holder.position.set(0,0,0); break;
        case 'apartmentbuilding': this.holder.position.set(-12,0,0); break;
        case 'apartment': this.holder.position.set(0,0,0); break;
      }
    });

    this.store.watch(state => state.area.shadow, (newShadow, oldSeason) => {
      var boolean = newShadow == "Shadow" && this.store.state.area.season == "Autumn";
      this.mustUpdate = boolean;
      this.holder.visible = boolean;
    });

    this.xr.CustomTextureLoader.load(leaveTex).then(tex => {
      this.leavesTexture = tex;
      this.Init();
    })

    return this.holder;
  }

  Init = () => {

    /*-------------------------------------------------------------*/
    const pointGeometry = new BufferGeometry();
    var verts = [];
    const velocities = [];
    const sizes = [];
    const colors = [];

    for (let i = 0; i < this.particleNum; i++) {
      //Verticies
      const x = -this.box.x + Math.floor(Math.random() * (this.box.x * 2));
      const y = -this.box.y + Math.floor(Math.random() * (this.box.y * 2));
      const z = -this.box.z + Math.floor(Math.random() * (this.box.z * 2));

      verts.push(x, y, z);


      //Velocity
      const vel_x = Math.floor(Math.random()) * 0.01;
      const vel_y = Math.floor(Math.random() * 10 + 3) * - 0.002;
      const vel_z = Math.floor(Math.random()) * 0.01;
      velocities.push(vel_x, vel_y, vel_z);

      //Size
      sizes.push(Math.random() * .8);

      //color
      let color = Utility.hexToRgbArray(this.colors.Autumn[Math.floor(1 + Math.random() * this.colors.Autumn.length - 1)]);
      colors.push(color[0] / 255, color[1] / 255, color[2] / 255);

    }

    var vertices = new Float32Array(verts)
    pointGeometry.setAttribute('position', new BufferAttribute(vertices, 3));

    var vertexColors = new Float32Array(colors);
    pointGeometry.setAttribute('customColor', new BufferAttribute(vertexColors, 3));

    var leaveSize = new Float32Array(sizes);
    pointGeometry.setAttribute('size', new BufferAttribute(leaveSize, 1));

    const material = new ShaderMaterial({

      uniforms: {
        color: { value: new Color(0xffffff) },
        pointTexture: { value: this.leavesTexture }
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      depthTest: false,
      transparent: true

    });


    this.particles = new Points(pointGeometry, material);
    this.particles.geometry.velocities = velocities;
    this.holder.visible = false;

    this.particles.position.set(3, 4, 8);
    this.holder.add(this.particles);
  }

  Animate = (t) => {
    if (!this.mustUpdate) { return; }

    const pos = this.particles.geometry.attributes.position.array;
    const velArr = this.particles.geometry.velocities;

    for (var i = 0; i < pos.length; i += 3) {

      const velocity = {
        x: velArr[i],
        y: velArr[i + 1],
        z: velArr[i + 2]
      }

      pos[i] += velocity.x;
      pos[i + 1] += velocity.y;
      pos[i + 2] += velocity.z;


      // Check Boundary
      if (pos[i + 1] < -this.box.y) {
        pos[i + 1] = this.box.y;
      }

      if (pos[i] < -this.box.x) {
        pos[i] = this.box.x;
      }

      if (pos[i + 2] < -this.box.z) {
        pos[i + 2] = this.box.z;
      }
    }

    this.particles.geometry.attributes.position.needsUpdate = true;
  }

}


export default AutumnParticlesController;