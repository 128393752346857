import {MeshStandardMaterial, Color, LinearEncoding} from 'three';

import aoMapHouse from '../models/textures/ao_house_scene_flipped.jpg';
import aoMapKitchen from '../models/textures/ao_kitchen_flipped.jpg';
import aoMapBasement from '../models/textures/ao_basement_flipped.jpg';
import aoMapRoof from '../models/textures/ao_roofs_flipped.jpg';
import aoMapBalcony from '../models/textures/ao_balcony.jpg';
import aoMapCar from '../models/textures/ao_car_flipped.jpg';
import aoMapHallway from '../models/textures/ao_hallway.jpg';
import aoMapLivingroom from '../models/textures/ao_livingroom.jpg';
import aoTinyCity from '../models/textures/ao_tinycity.jpg';
import aoUndergroundGarage from '../models/textures/ao_undergroundgarage.jpg';
import aoInstallationroom from '../models/textures/ao_installationsroom.jpg';
import aoApartmentBuilding from '../models/textures/ap_aptbuilding.jpg';

import lightMap_basement from '../models/textures/lightmap_basement_flipped.jpg';
import lightMap_installationsroom from '../models/textures/lightmap_installationsroom.jpg';
import lightMap_apartmentBuilding from '../models/textures/lightmap_aptbuilding.jpg';
import lightMap_hallway from '../models/textures/lightmap_hallway.jpg';
import lightMap_kitchen from '../models/textures/lightmap_kitchen_flipped.jpg';
import lightMap_livingroom from '../models/textures/lightmap_livingroom.jpg';
import lightMap_balcony from '../models/textures/lightmap_balcony.jpg';
import lightMap_undergroundgarage from '../models/textures/lightmap_undergroundgarage.jpg';


import mainConfig from '../../main.config';

class SceneLoader {
  constructor(context) {
    this.mainScene = context;
    this.store = context.store;
    this.context = context.xr;
    this.envMap = null;
    this.rooms = [];
    this.aoMaps = null;
    this.lightmaps = null;
    this.doublSidedObject = ['Plane002', 'Plane012', 'Plane036']

  }

  loadAoMap = (map, room, name) => {
    return this.context.CustomTextureLoader.load(map).then((texture) => {
      if(this.aoMaps == null){this.aoMaps = [];}
      texture.encoding = LinearEncoding;
      this.aoMaps.push({texture: texture, room: room, name: name});
    });
  }
  
  loadLightMap = (map, room, name) => {
    return this.context.CustomTextureLoader.load(map).then((texture) => {
      if(this.lightmaps == null){this.lightmaps = [];}
      texture.encoding = LinearEncoding;
      this.lightmaps.push({texture: texture, room: room, name: name});
    });
  }

  loadAllLightMaps = ()=>{
    
    return Promise.all([
      this.loadLightMap(lightMap_installationsroom, 'InstallationsRoom', 'All'),
      //this.loadLightMap(lightMap_basement, 'Basement', 'All'),
      this.loadLightMap(lightMap_hallway, 'Hallway', 'All'),
      this.loadLightMap(lightMap_kitchen, 'Kitchen', 'All'),
      this.loadLightMap(lightMap_livingroom, 'Livingroom', 'All'),
      this.loadLightMap(lightMap_balcony, 'Balcony', 'All'),
      this.loadLightMap(lightMap_undergroundgarage, 'UndergroundGarage', 'All'),
      this.loadLightMap(lightMap_apartmentBuilding, 'ApartmentBuilding', 'All'),
    ]).then(()=>{
      return this.lightmaps;

    });
  }

  loadAllAoMaps = ()=>{
  
    return Promise.all([
      this.loadAoMap(aoTinyCity, 'TinyCity', 'All'),
      this.loadAoMap(aoUndergroundGarage, 'UndergroundGarage', 'All'),
      this.loadAoMap(aoMapHouse, 'House', 'House'),
      this.loadAoMap(aoApartmentBuilding, 'ApartmentBuilding', 'All'),
      this.loadAoMap(aoMapKitchen, 'Kitchen', 'All'),
      this.loadAoMap(aoMapBasement, 'Basement', 'All'),
      this.loadAoMap(aoMapCar, 'House', 'Car'),
      this.loadAoMap(aoMapRoof, 'House', 'All'),
      this.loadAoMap(aoMapHallway, 'Hallway', 'All'),
      this.loadAoMap(aoMapBalcony, 'Balcony', 'All'),
      this.loadAoMap(aoMapLivingroom, 'Livingroom', 'All'),
      this.loadAoMap(aoInstallationroom, 'InstallationsRoom', 'All'),
    ]).then(()=>{

      console.log("AoMaps geladen");
      
      return this.aoMaps;

    });
  }

  setRooms = (rooms) => {
    this.rooms = rooms;
  }

  loadModels = async (roomName, stackToLoad) => {

    console.log("loadModels" , roomName, stackToLoad, this.rooms[roomName]);

    //Bereits geladen
    if(!this.rooms[roomName].isLoading && Object.keys(this.rooms[roomName].ModelStack).length != 0){
      this.rooms[roomName].isLoading = true;
      return new Promise((resolve,reject)=>{

        const roomObjects = {};
        stackToLoad.map((stackElement)=>{
          if(Object.prototype.hasOwnProperty.call(this.store.state.world.Library, stackElement.name)){
            roomObjects[stackElement.name] = this.store.state.world.Library[stackElement.name];
          }
        });
        resolve(roomObjects);
      }).then(stack =>{
        this.store.commit("world/SetRoomProgress", {
          room: roomName,
          progress: 1
        });

        this.store.commit("world/SetRoomLibrary", {
          stack: stack,
          room: roomName
        });
        
      });
    }
   
    var loadObject = {
      roomName: roomName,
      isLoading: true,
      stack: {}
    };

    stackToLoad.map((stackElement) => {
      stackElement.progress = 0;
      stackElement.isLoading = true;
      loadObject.stack[stackElement.name] = stackElement;
    });
    
    return new Promise((resolve, reject)=>{
      if(this.aoMaps != null){
        resolve(this.aoMaps);
        return;
      }

      else{
        resolve(this.loadAllAoMaps().then(()=>this.loadAllLightMaps()));
        return;
      }
    }).then(()=>{

      this.context.Loader.loadStack({
        stack: stackToLoad,

        OnProgress: (opt) => {

          if (! loadObject.stack.hasOwnProperty(opt.name)) {
            return;
          }

          loadObject.stack[opt.name].progress = opt.progress;
          loadObject.stack[opt.name].isLoading = opt.isLoading;

          // //const globalProgress = 1;
          const globalProgress = Object.keys(loadObject.stack).map((stackName) => {
            let progress = loadObject.stack[stackName].progress;
            return progress;
          }).reduce((a, b) => {
            return a + b;
          });

          this.store.commit("world/SetRoomProgress", {
            room: roomName,
            progress: globalProgress / Object.keys(loadObject.stack).length
          });
        }
      }).then((stack) => {

        

        if(roomName != "default"){
          this.rooms[roomName].SetModelStack(stack, this.aoMaps, this.lightmaps);
        }
        this.rooms[roomName].isLoading = false;
        // ParticipationController
        this.mainScene.participationController.AddMaterialObjects(stack);
        // ElevatorController
        this.mainScene.elevatorController.AddObjects(stack);

        this.mainScene.interactiveObjectController.AddInteractiveObjects(stack);


        this.mainScene.configObjectController.AddConfigObjects(stack);
        this.mainScene.seasonController.AddSeasonObjects(stack);
        this.mainScene.materialController.AddMaterialObjects(stack);

        this.store.commit("world/SetRoomLibrary", {
          stack: stack,
          room: roomName
        });
        this.store.commit("world/SetRoomProgress", {
          room: roomName,
          progress: 1
        });


        console.log("%c Library geladen => SceneReady:true und Library sind im Store", "background:green;  color:#fff");
        //console.log("Library", stack, roomName, this.store.state.world.Library);

        this.store.state.world.mainScene.xr.Renderer.instance.shadowMap.needsUpdate = true;

        
        return stack;
      });
    });
  }

}

export default SceneLoader;
