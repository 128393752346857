import IRoom from "./IRoom";
import {AmbientLight ,RectAreaLight} from 'three';
import SolarzellenController from "./Solarzelle/SolarzellenController";
import EnergyParticles from "./Solarzelle/EnergyParticles";
import { RectAreaLightUniformsLib } from './RectAreaLightUniformsLib.js'
import { Mesh, SphereGeometry, MeshBasicMaterial, BackSide, Color } from 'three'


class RoomSolarzelle implements IRoom {
    Name: String;
    Xr: any;
    ModelStack: Array<any>;
    SolarzellenController: SolarzellenController;
    Store;

    isLoading: boolean = true;
    constructor(xr: any, store: any) {
        this.Name = 'Solarzelle';
        this.Xr = xr;
        this.Store = store
        this.ModelStack = [];
        this.SolarzellenController = new SolarzellenController({ xr: this.Xr, store: this.Store });
        this.Init();
    }


    Init(): void {
        const light = new AmbientLight(0xffffff, 1);
        this.Xr.SceneController.AddToScene(this.Name, light);

        // var geometry = new SphereGeometry(80, 80, 20);
        // //var material = new MeshBasicMaterial({color: new Color(49/600,51/600,67/600), side: BackSide });
        // var material = new MeshBasicMaterial({ color: new Color(1, 1, 1), side: BackSide });

        // var Skybox = new Mesh(geometry, material);
        // this.Xr.SceneController.AddToScene(this.Name, Skybox);

        const width = 5;
        const height = 5;
        const intensity = 2;
        const rectLight = new RectAreaLight(0xffffff, intensity, width, height);
        rectLight.position.set(2,2,2);
        rectLight.lookAt(0, 0, 0);

        RectAreaLightUniformsLib.init();
        this.Xr.SceneController.AddToScene(this.Name,rectLight);

    }

    SetModelStack(stack: any, maps: any) {
        var mapForRoom = maps.filter(map => { return map.room == this.Name })[0];

        Object.values(stack).map((model: any) => {
            if (model.scene.userData.Room == this.Name) {
                //AoMap
                /*if (mapForRoom != null && mapForRoom != undefined) {
                    model.scene.traverse((child) => {
                        if (child.material != undefined) {
                            child.material.aoMap = mapForRoom.texture;
                        }
                    })
                }*/
                this.ModelStack.push(model);
                this.Xr.SceneController.AddToScene(this.Name, model.scene)
            }
        })
    }


    Detach(arg: any): void {
        throw new Error("Method not implemented.");
    }
    Attach(arg: any): void {
        throw new Error("Method not implemented.");
    }
}

export default RoomSolarzelle;