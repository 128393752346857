import IRoom from "./IRoom";
import {AmbientLight, DirectionalLight} from 'three';

class RoomBasement implements IRoom {
  Name : String;
  Xr : any;
  ModelStack : Array < any >;

  isLoading: boolean = true;
  constructor(xr : any) {
    this.Name = 'Basement';
    this.Xr = xr;
    this.ModelStack = [];
    this.Init();
  }

  Init(): void {


    const light = new AmbientLight(0xffffff, 1);
    this.Xr.SceneController.AddToScene(this.Name, light);

    var directionalLight = new DirectionalLight(0xffffff, 2.5);
    directionalLight.castShadow = true;
    // Set up shadow properties for the light
    directionalLight.shadow.mapSize.width = 1024; // default
    directionalLight.shadow.mapSize.height = 1024; // default
    directionalLight.shadow.camera.near = .01; // default
    directionalLight.shadow.camera.far = 70; // default
    directionalLight.shadow.bias = -0.01; // default
    directionalLight.position.set(10, 20, 10);

    this.Xr.SceneController.AddToScene(this.Name, directionalLight);


    //console.log("Room %c Basement ist geladen", "background:#ff9800; color:#fff;");


  }

  SetModelStack(stack, maps, lightMap) {
    var mapForRoom = maps.filter(map => {
      return map.room == this.Name
    })[0];
    
    var lightMapForRoom = lightMap.filter(map => {
      return map.room == this.Name
    })[0];

    Object.values(stack).map((model : any) => {
      if (model.scene.userData.Room == this.Name) { // AoMap
        if (mapForRoom != null && mapForRoom != undefined) {
          model.scene.traverse((child) => {
            if (child.material != undefined) {
              child.material.aoMap = mapForRoom.texture;
            }
          });
        }
        
        if (lightMapForRoom != null && lightMapForRoom != undefined) {
          model.scene.traverse((child) => {
            if (child.material != undefined) {
              child.material.lightMap = lightMapForRoom.texture;
              child.material.lightMapIntensity = lightMapForRoom.texture;
            }
          });
        }

        this.ModelStack.push(model)
        this.Xr.SceneController.AddToScene(this.Name, model.scene)
      }
    });

    this.Xr.Renderer.instance.shadowMap.needsUpdate = true;
  }

  Detach(arg : any): void {
    throw new Error("Method not implemented.");
  }
  Attach(arg : any): void {
    throw new Error("Method not implemented.");
  }
}

export default RoomBasement;
